import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox, PrimaryButton } from "common";
import dayjs from "dayjs";
import { signupSelectors } from "store/signup-slice/selectors";
import { signupActions } from "store/signup-slice/slice";
import { isLoading } from "utils/constants";
import { getStorage } from "utils/helpers";
import { signUpStep2Schema } from "validations";

import Properties from "./Properties";
import { PropertyInformation } from "./PropertyInformation";
import * as S from "./styled";

const Step2 = () => {
  const { properties, ownerData, signupStatus } = useSelector(signupSelectors);

  const owner_id = getStorage("owner_id");

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signUpStep2Schema),
  });

  const onSubmit = () => {
    const body = {
      first_name: ownerData.firstName,
      last_name: ownerData.lastName,
      email: ownerData.email,
      phone_number: ownerData.phoneNumber,
      sign_up_date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      display_clients: 1,
      sign_up_step_number: 2,
    };
    dispatch(signupActions.updateOwner({ body, id: owner_id }));
    dispatch(signupActions.resetErrorMessages());
  };

  const handleBackClick = (e) => {
    reset();
    e.preventDefault();
    dispatch(signupActions.backStep());
    dispatch(signupActions.resetErrorMessages());
  };

  return (
    <>
      <PropertyInformation />
      <S.Form className="flex w-full flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {!!properties.length && (
          <>
            <S.Box>
              <S.PropertyTitle>Your Properties</S.PropertyTitle>
              <S.PropertyDescription>You are signing up with the following properties:</S.PropertyDescription>
              <S.Box>
                <Properties isDelete />
              </S.Box>
              <S.Box>
                <Checkbox
                  description="This is residental property"
                  error={errors.firstCheckbox}
                  id="this is residental property"
                  register={register("firstCheckbox")}
                />
              </S.Box>
              <S.Box>
                <Checkbox
                  description="I understand my protest renews yearly and that I can opt out anytime before my protest is complete"
                  error={errors.secondCheckbox}
                  id="i confirm i understand that"
                  register={register("secondCheckbox")}
                />
              </S.Box>
            </S.Box>
          </>
        )}
        {properties.length < 1 ? null : (
          <S.ButtonsContainer>
            <PrimaryButton label="continue" loading={isLoading(signupStatus)} minwidth={200} />
            <PrimaryButton eventHandler={handleBackClick} label="back" mode="gray" />
          </S.ButtonsContainer>
        )}
      </S.Form>
    </>
  );
};

export default Step2;
